/* src/components/authorPage/AuthorPage.module.css */

.authorPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: var(--color-10);
}

.content {
  display: flex;
  padding: 20px;
  gap: 20px;
  height: calc(100vh - 70px);
}

@media (max-width: 768px) {
  .authorPage {
    background-color: #f9faf7;
  }
  .content {
    background-color: #f9faf7;
    margin-top: 24px;
    flex-direction: column;
    height: auto; /* Let height auto-expand for stacked layout */
  }
}
