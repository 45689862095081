/* MainPage.module.css */

/* Main container */
.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* Remove padding to let Header span full width */
  background-color: var(--color-9); /* Gray/100 */
  height: 100vh; /* Fill the full viewport height */
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%; /* Ensure full width */
}

/* About Me button */
.aboutMeButton {
  font-family: 'Crimson Pro', serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-11); /* Gray/500 */
  background-color: #f4f4f0; /* Light background */
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow:
    0px 4px 12px rgba(30, 55, 66, 0.06),
    0px 2px 4px rgba(30, 55, 66, 0.06);
  transition: background-color 0.3s;
}

.aboutMeButton:hover {
  background-color: #e5e7e0; /* Slightly darker on hover */
}
