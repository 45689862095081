/* src/components/authorEdit/AuthorDashboard.module.css */

.dashboard {
  display: flex;
  min-height: calc(100vh - var(--header-height));
  background-color: var(--color-10);
}

.nav {
  width: 240px;
  background-color: var(--color-11);
  color: var(--color-8);
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav a {
  display: block;
  color: var(--color-12);
  text-decoration: none;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 8px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.nav a:hover {
  background-color: var(--color-13);
  border-radius: 8px;
}

.nav a.active {
  background-color: var(--color-13);
  color: var(--color-8) !important;
  font-weight: bold;
  border-radius: 8px;
}

.content {
  flex: 1;
  padding: 0px 20px 20px 20px;
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }

  .nav {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
  }

  .nav a {
    font-size: 14px;
    padding: 8px 12px;
  }
}
