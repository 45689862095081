.documentListContainer {
  padding: 24px;
  background-color: #f8f9fa;
  min-height: 100%;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.header h2 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a2e;
  margin: 0;
}

.addButton {
  background-color: #4361ee;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(67, 97, 238, 0.15);
}

.addButton:hover {
  background-color: #3a56d4;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(67, 97, 238, 0.2);
}

.addButton:active {
  transform: translateY(0);
}

/* Card-based table design */
.documentTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;
  margin-bottom: 24px;
}

.documentTable th {
  background-color: transparent;
  color: #6c757d;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 12px 16px;
  border: none;
  text-align: left;
}

.documentTable td {
  background-color: white;
  padding: 16px;
  border: none;
  color: #495057;
  font-size: 14px;
}

/* First cell in a row */
.documentTable tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* Last cell in a row */
.documentTable tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.documentTable tr {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.documentTable tr:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Status badges */
.statusBadge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.statusPending {
  background-color: #fff3cd;
  color: #856404;
}

.statusIndexing {
  background-color: #cce5ff;
  color: #004085;
}

.statusReady {
  background-color: #d4edda;
  color: #155724;
}

.statusError {
  background-color: #f8d7da;
  color: #721c24;
}

/* Link styling */
.documentLink {
  color: #4361ee;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
}

.documentLink:hover {
  color: #3a56d4;
  text-decoration: underline;
}

/* Action buttons */
.actionButtons {
  display: flex;
  gap: 8px;
}

.editButton,
.deleteButton {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editButton {
  background-color: #e9ecef;
  color: #495057;
}

.editButton:hover {
  background-color: #dee2e6;
  color: #212529;
}

.deleteButton {
  background-color: #f8d7da;
  color: #721c24;
}

.deleteButton:hover {
  background-color: #f5c2c7;
  color: #5c171d;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.pagination button {
  background-color: white;
  color: #4361ee;
  border: 1px solid #e9ecef;
  padding: 10px 16px;
  margin: 0 5px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pagination button:hover:not(:disabled) {
  background-color: #4361ee;
  color: white;
  border-color: #4361ee;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(67, 97, 238, 0.15);
}

.pagination button:disabled {
  background-color: #f8f9fa;
  color: #adb5bd;
  cursor: not-allowed;
  border-color: #e9ecef;
}

.pagination span {
  margin: 0 12px;
  font-weight: 500;
  color: #495057;
}

/* Empty state */
.emptyState {
  text-align: center;
  padding: 48px 0;
  color: #6c757d;
}

.emptyState h3 {
  font-size: 20px;
  margin-bottom: 12px;
  color: #495057;
}

.emptyState p {
  font-size: 14px;
  margin-bottom: 24px;
}

.loadingState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  color: #666;
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(67, 97, 238, 0.3);
  border-radius: 50%;
  border-top-color: #4361ee;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.errorMessage {
  background-color: #ffebee;
  color: #c62828;
  padding: 12px 16px;
  margin: 0 0 20px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.errorMessage::before {
  content: '⚠️';
  margin-right: 8px;
}
