/* src/components/authorPage/ToolsMobile.module.css */

/* The container for all tool cards. */
.toolsMobileContainer {
  display: flex;
  flex-direction: column;
  gap: 8px; /* space between each card */
  padding-top: 16px;
  padding-bottom: 16px;
}

/* Each “card” with icon, text, divider, and button. */
.toolCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background:
    linear-gradient(0deg, #eff0f3, #eff0f3),
    linear-gradient(180deg, var(--color-10) 0%, #e5e6e0 100%);
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  isolation: isolate; /* from Figma */
  box-sizing: border-box;

  /* We can let it fill the width of the container,
       or if you want a max width you can do:
       width: 100%;
       max-width: 335px;
       margin: 0 auto;
    */
}

/* The grey background behind the icon. */
.iconContainer {
  width: 55px;
  height: 55px;
  background: #e4e1ea;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.toolIcon {
  color: #6f499d;
}

/* The text block.
   flex: 1 => grows so that divider & button stay to the right */
.textContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
}

/* Title + Subtitle styles */
.toolTitle {
  font-family: 'Hanken Grotesk', sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: normal;
  color: #6f499d;
}

.toolSubtitle {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 11px;
  line-height: normal;
  color: var(--color-11);
  opacity: 0.7;
  font-weight: 400;
  text-overflow: ellipsis;
}

/* The vertical divider.
   Figma calls for a 1px line, 55px tall. */
.divider {
  width: 1px;
  height: 55px;
  background-color: rgba(163, 173, 176, 0.19);
}

/* The "play" button on the right.
   Typically 24px x 24px in Figma. */
.playButton {
  color: #6f499d;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  cursor: pointer;
  transition: transform 0.2s;
}

.playButton:hover {
  transform: scale(1.1);
}
