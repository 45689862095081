/* client/src/components/auth/LoginPage.module.css */
.authPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-10); /* Matches the background of AuthorPage */
  font-family: 'Hanken Grotesk', sans-serif;
  padding: 20px;
}

.logoContainer {
  margin-bottom: 30px;
}

.logo {
  width: 120px;
  height: auto;
}

.authForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 30px;
  background-color: white;
  border: 1px solid var(--color-12);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-family: 'Crimson Pro', serif;
  font-size: 24px;
  color: var(--color-11);
  text-align: center;
  margin-bottom: 10px;
}

.subtitle {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 16px;
  color: #6f6f6f;
  text-align: center;
  margin-bottom: 20px;
}

.label {
  margin-bottom: 15px;
  font-size: 14px;
  color: var(--color-11);
  display: flex;
  flex-direction: column;
}

.input {
  padding: 12px;
  font-size: 14px;
  border: 1px solid var(--color-12);
  border-radius: 6px;
  margin-top: 5px;
  outline: none;
  font-family: 'Hanken Grotesk', sans-serif;
}

.input:focus {
  border-color: var(--color-13);
  box-shadow: 0 0 5px rgba(33, 128, 141, 0.3);
}

.button {
  padding: 12px;
  font-size: 16px;
  height: 48px;
  background-color: var(--color-13);
  border: none;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 8px;
  font-family: 'Hanken Grotesk', sans-serif;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #18636e;
}

.secondaryButton {
  padding: 10px 12px;
  font-size: 14px;
  color: var(--color-13);
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-family: 'Hanken Grotesk', sans-serif;
  align-self: center; /* Center the button */
  transition: color 0.3s ease;
}

.secondaryButton:hover {
  color: #18636e;
}

.errorMessage {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
