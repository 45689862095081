.graphContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.graphTitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-11);
  margin-bottom: 12px;
}

.chartWrapper {
  width: 100%;
  height: 300px; /* Adjust as needed */
}
