.mainContainer {
  position: relative;
  width: 100%;
  background: #f9f9f5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Ensure main container takes full viewport height */
}

.noiseOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/author-landing/noise.png') lightgray 0 0 / 60px 60px repeat;
  opacity: 0.04;
  pointer-events: none;
  z-index: 0;
}

/* Header Section */
.headerSection {
  width: 100%;
  padding: 50px 16px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1194px;
  margin-bottom: 78px;
  padding: 0 16px;
  box-sizing: border-box;
}

.logo {
  width: 140px;
  height: auto;
}

.contactContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
}

.contact {
  font-family: 'Hanken Grotesk', serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #1e3842;
  text-decoration: none;
}

.reserveSpot {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  border: 1px solid #1e3842;
  color: #1e3842;
  text-align: center;
  font-family: 'Hanken Grotesk', serif;
  font-size: 16px;
  cursor: pointer;
}

.topTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 936px;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
}

.topTitle {
  font-family: 'Spectral', serif;
  font-weight: 275;
  font-size: clamp(40px, 8vw, 99px);
  line-height: 1.1;
  letter-spacing: -0.05em;
  color: #1e3842;
  margin: 0;
}

.topSubtitle {
  max-width: 565px;
  font-family: 'Hanken Grotesk', serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: var(--color-11);
  opacity: 0.8;
  margin-top: 16px;
  text-align: center;
}

.topReserveYourSpot {
  margin-top: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;
  background: #1e3842;
  border-radius: 35px;
  font-family: 'Hanken Grotesk', serif;
  font-weight: 400;
  font-size: 18px;
  color: #f3f3ee;
  cursor: pointer;
  z-index: 1;
}

.heroImageSection {
  width: 100%;
  position: relative;
}

.treeLeafsContainer {
  display: none;
}

.treeImageContainer {
  margin-top: -130px;
  width: 100%;
}

.bottomPageContainer {
  position: relative;
  align-items: center;
  width: 100%;
  padding-top: 150px;
  flex-grow: 1; /* Allow bottomPageContainer to expand and push content to bottom */
  display: flex;
  flex-direction: column; /* Added to control vertical layout */
}

.treeImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: auto;
}

.treeTopImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: auto;
}

.treeImage[data-screen='large'] {
  display: block;
}

.treeImage[data-screen='small'] {
  display: none;
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 64px;
  align-items: center;
  width: 100%;
  padding: 16px 72px;
  z-index: 10;
}

.formWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
}

.phoneContainer {
  position: absolute;
  top: 87px;
  left: 50%;
  transform: translateX(-50%);
  width: clamp(240px, 20vw, 317px); /* Width scales from 240px to 317px */
  height: auto; /* Height adjusts automatically */
  max-width: 80%;
}

.phoneImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.readerCardTopContainer,
.readerCardBottomContainer,
.authorCardContainer {
  position: absolute;
  width: 390px;
  padding: 16px;
  gap: 15px;
  border-radius: 13px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 8px 36px rgba(0, 0, 0, 0.17);
  backdrop-filter: blur(5px);
  opacity: 0;
}

.readerCardTopContainer {
  top: 597px;
  transform: translateX(-130%);
  background: rgba(250, 251, 249, 0.94);
}

.readerCardBottomContainer {
  top: 907px;
  transform: translateX(-99%);
  background: rgba(250, 251, 249, 0.94);
  z-index: 10;
}

.authorCardContainer {
  top: 747px;
  transform: translateX(18%);
  background: rgba(30, 56, 66, 0.93);
  color: #fff;
}

@keyframes fadeInUpCard1 {
  from {
    transform: translate3d(-130%, 50px, 0); /* Maintain X, move Y */
    opacity: 0;
  }
  to {
    transform: translate3d(-130%, 0, 0); /* Maintain X, reset Y */
    opacity: 1;
  }
}

@keyframes fadeInUpCard3 {
  from {
    transform: translate3d(-99%, 50px, 0); /* Maintain X, move Y */
    opacity: 0;
  }
  to {
    transform: translate3d(-99%, 0, 0); /* Maintain X, reset Y */
    opacity: 1;
  }
}

@keyframes fadeInUpCard2 {
  from {
    transform: translate3d(18%, 50px, 0); /* Maintain X, move Y */
    opacity: 0;
  }
  to {
    transform: translate3d(18%, 0, 0); /* Maintain X, reset Y */
    opacity: 1;
  }
}

.cardAnimation1 {
  animation: fadeInUpCard1 1s ease-out forwards;
  opacity: 0;
}

.cardAnimation2 {
  animation: fadeInUpCard2 1s ease-out forwards;
  opacity: 0;
}

.cardAnimation3 {
  animation: fadeInUpCard3 1s ease-out forwards;
  opacity: 0;
}

.delay1 {
  animation-delay: 0.2s;
}

.delay2 {
  animation-delay: 0.5s;
}

.delay3 {
  animation-delay: 0.8s;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.readerCardImage {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: url('../../assets/author-landing/reader-card-image.png') no-repeat center/cover;
}

.authorCardImage {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: url('../../assets/author-landing/author-card-image.png') no-repeat center/cover;
}

.readerCardChip,
.authorCardChip {
  display: flex;
  align-items: center;
  font-family: 'Hanken Grotesk', serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  border-radius: 30px;
  padding: 4px 8px;
}

.readerCardChip {
  border: 1px solid rgba(108, 124, 131, 0.64);
  color: var(--color-11);
}

.authorCardChip {
  border: 1px solid var(--color-11);
  background: rgba(255, 255, 255, 0.66);
  color: var(--color-11);
  padding: 4px 10px 4px 6px;
}

.readerCardText,
.authorCardText {
  font-family: Spectral, serif;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.661px;
  color: inherit;
}

.middleSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 100%;
  padding: 60px 16px;
  box-sizing: border-box;
}

.midSectionTitle {
  color: #1e3842;
  text-align: center;
  font-family: Spectral, serif;
  font-size: clamp(24px, 5vw, 46px);
  font-weight: 275;
  letter-spacing: -2.3px;
}

.midSectionEcho {
  font-style: italic;
  font-weight: 400;
}

.leafsAndTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.leftLeafs {
  width: 300px;
  height: 470px;
  background: url(../../assets/author-landing/leftLeafs.png) no-repeat center/contain;
  flex-shrink: 0;
}

.textInTheMiddle {
  max-width: 720px;
  margin: 150px 16px 0 16px;
  color: #1e3842;
  text-align: center;
  font-family: Spectral, serif;
  font-weight: 300;
  line-height: 126%;
  letter-spacing: -1.6px;
  font-size: clamp(20px, 5vw, 40px);
  flex: 1;
}

.rightLeafs {
  width: 125px;
  padding-left: 175px; /* Addding padding on left such that all textInTheMiddle will be evenly at the middle*/
  height: 129px;
  background: url(../../assets/author-landing/rightLeafs.png) no-repeat center/contain;
  flex-shrink: 0;
  margin-bottom: -98px;
}

@media (max-width: 1024px) {
  .bottomPageContainer {
    padding-top: 400px;
  }

  .middleSectionContainer {
    margin-top: 50px;
  }

  .header {
    padding: 0 24px;
  }

  .midSectionTitle {
    font-size: 46px;
  }

  .contactContainer {
    display: none;
  }
}

@media (max-width: 768px) {
  .bottomPageContainer {
    padding-top: 26px;
  }

  .treeImage {
    position: relative;
    object-position: right;
    margin-top: -200px;
    order: 2;
    height: 400px;
    z-index: 0;
    object-fit: cover;
    width: 100%;
  }

  .treeTopImage {
    position: relative;
    object-position: right;
    height: 200px;
    width: 100%;
  }

  .treeImage[data-screen='large'] {
    display: none;
  }

  .treeImage[data-screen='small'] {
    display: block;
  }

  .formWrapper {
    align-self: center;
    margin-top: 40px;
    display: flex;
    order: 1;
    z-index: 2;
    box-sizing: border-box;
    position: relative; /* Return to static positioning for smaller screens */
    transform: none;
  }

  .footer {
    width: 333px;
    height: 40px;
    position: relative;
    z-index: 10;
    justify-content: center;
    order: 3;
    padding: 0;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .logo {
    color: var(--color-11) !important;
  }

  .headerSection {
    padding: 0;
  }

  .topTitleContainer {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .topTitle {
    font-size: 60px;
    align-items: flex-start;
    text-align: start;
    width: 70%;
  }

  .topSubtitle {
    align-items: flex-start;
    text-align: start;
  }

  .treeLeafsContainer {
    display: flex;
    right: 0;
    position: absolute;
  }

  .treeImageContainer {
    display: none;
  }

  .header {
    margin-top: 32px;
    padding-left: 20px;
    margin-bottom: 56px;
  }

  .phoneContainer {
    position: static;
    transform: none;
    width: 80%;
    margin: 20px auto 0 auto;
  }

  .readerCardTopContainer,
  .readerCardBottomContainer,
  .authorCardContainer {
    transform: none;
    left: 50%;
    width: 90%;
    opacity: 0;
  }

  .readerCardTopContainer {
    top: 690px;
  }

  .authorCardContainer {
    top: 840px;
  }

  .readerCardBottomContainer {
    top: 990px;
  }

  .cardAnimation1 {
    animation: fadeInUpMobile 1s ease-out forwards;
    animation-delay: 0.2s; /* Apply delay directly for card 1 */
    opacity: 0;
  }

  .cardAnimation2 {
    animation: fadeInUpMobile 1s ease-out forwards;
    animation-delay: 0.5s; /* Apply delay directly for card 2 */
    opacity: 0;
  }

  .cardAnimation3 {
    animation: fadeInUpMobile 1s ease-out forwards;
    animation-delay: 0.8s; /* Apply delay directly for card 3 */
    opacity: 0;
  }

  @keyframes fadeInUpMobile {
    from {
      transform: translate3d(-50%, 50px, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(-50%, 0, 0);
      opacity: 1;
    }
  }

  .leafsAndTextContainer {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .leftLeafs {
    align-self: flex-start;
    margin-top: -285px;
    height: 276px;
    width: 175px;
    opacity: 1;
    background: url(../../assets/author-landing/leftLeafs.png);
    background-position: right; /* Position image from the right */
    background-size: auto 100%; /* Make sure it covers the full height of the container */
    background-repeat: no-repeat; /* Prevent the image from repeating */
  }

  .rightLeafs {
    align-self: flex-end;
    padding: 0;
    width: 104px;
  }

  .textInTheMiddle {
    padding-top: 42px;
    align-self: flex-start;
    text-align: start;
    margin: 0 32px;
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  .topSubtitle {
    font-size: 16px;
    width: 100%;
  }

  .formWrapper {
    padding: 0;
  }
}
