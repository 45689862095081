.sidebar {
  width: 240px;
  background-color: var(--color-11); /* Dark primary background */
  color: var(--color-8); /* Text on dark background */
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-8);
  text-align: center;
  margin-bottom: 20px;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  margin-bottom: 10px;
}

.link {
  display: block;
  color: var(--color-12);
  text-decoration: none;
  font-size: 16px;
  padding: 10px 16px;
  border-radius: 8px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.link:hover {
  background-color: var(--color-13);
  color: var(--color-8);
}

.activeLink {
  text-decoration: none;
  display: block;
  background-color: var(--color-13);
  color: var(--color-8);
  border-radius: 8px;
  font-weight: bold;

  padding: 10px 16px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
  }

  .menu {
    display: flex;
    gap: 12px;
  }

  .menu li {
    margin-bottom: 0;
  }
}
