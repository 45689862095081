.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.modalContent {
  background-color: white;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
.modalBody {
  padding: 24px 0;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  background-color: #f8f9fa;
  border-radius: 12px 12px 0 0;
}

.modalHeader h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #1a1a2e;
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #666;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.closeButton:hover {
  color: #1a1a2e;
  background-color: rgba(0, 0, 0, 0.05);
}

.formGroup {
  margin-bottom: 20px;
  padding: 0 24px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
  font-size: 0.95rem;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
  background-color: #fff;
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.1);
}

.formGroup textarea {
  resize: vertical;
  min-height: 100px;
}

.imagePreview {
  margin-top: 15px;
  text-align: center;
}

.imagePreview img {
  max-width: 250px;
  max-height: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  padding: 20px 24px;
  border-top: 1px solid #eee;
  background-color: #f8f9fa;
  border-radius: 0 0 12px 12px;
}

.cancelButton,
.saveButton {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
  transition: all 0.2s ease;
}

.cancelButton {
  background-color: #f1f1f1;
  color: #333;
}

.cancelButton:hover {
  background-color: #e1e1e1;
}

.saveButton {
  background-color: #4361ee;
  color: white;
}

.saveButton:hover {
  background-color: #3a56d4;
  transform: translateY(-1px);
}

.saveButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
  transform: none;
}

.error {
  background-color: #ffebee;
  color: #c62828;
  padding: 12px 16px;
  margin: 0 24px 20px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.error::before {
  content: '⚠️';
  margin-right: 8px;
}

.readOnlyField {
  background-color: #f8f9fa;
  cursor: not-allowed;
  color: #555;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  display: block;
  width: 100%;
}

.sourceLink {
  display: inline-block;
  color: #4361ee;
  text-decoration: none;
  font-weight: 500;
  padding: 8px 0;
  transition: color 0.2s ease;
}

.sourceLink:hover {
  color: #3a56d4;
  text-decoration: underline;
}

.removeButton {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.removeButton:hover {
  background-color: #d32f2f;
}

.radioGroup {
  display: flex;
  gap: 20px;
  margin-top: 8px;
}

.radioGroup label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.radioGroup input[type='radio'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
