@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-background: oklch(1 0 0);
    --color-foreground: oklch(0.145 0 0);
    --color-card: oklch(1 0 0);
    --color-card-foreground: oklch(0.145 0 0);
    --color-popover: oklch(1 0 0);
    --color-popover-foreground: oklch(0.145 0 0);
    --color-primary: oklch(0.205 0 0);
    --color-primary-foreground: oklch(0.985 0 0);
    --color-secondary: oklch(0.97 0 0);
    --color-secondary-foreground: oklch(0.205 0 0);
    --color-muted: oklch(0.97 0 0);
    --color-muted-foreground: oklch(0.556 0 0);
    --color-accent: oklch(0.97 0 0);
    --color-accent-foreground: oklch(0.205 0 0);
    --color-destructive: oklch(0.577 0.245 27.325);
    --color-destructive-foreground: oklch(0.577 0.245 27.325);
    --color-border: oklch(0.922 0 0);
    --color-input: oklch(0.922 0 0);
    --color-ring: oklch(0.708 0 0);
    --color-chart-1: oklch(0.646 0.222 41.116);
    --color-chart-2: oklch(0.6 0.118 184.704);
    --color-chart-3: oklch(0.398 0.07 227.392);
    --color-chart-4: oklch(0.828 0.189 84.429);
    --color-chart-5: oklch(0.769 0.188 70.08);
    --color-sidebar: oklch(0.985 0 0);
    --color-sidebar-foreground: oklch(0.145 0 0);
    --color-sidebar-primary: oklch(0.205 0 0);
    --color-sidebar-primary-foreground: oklch(0.985 0 0);
    --color-sidebar-accent: oklch(0.97 0 0);
    --color-sidebar-accent-foreground: oklch(0.205 0 0);
    --color-sidebar-border: oklch(0.922 0 0);
    --color-sidebar-ring: oklch(0.708 0 0);
    --radius: 0.625rem;
    --radius-sm: calc(var(--radius) - 4px);
    --radius-md: calc(var(--radius) - 2px);
    --radius-lg: var(--radius);
    --radius-xl: calc(var(--radius) + 4px);

    --color-zinc-50: 254 251 246;
    --color-zinc-100: 248 245 238;
    --color-zinc-200: 245 242 234;
    --color-zinc-300: 226 223 213;
    --color-zinc-400: 193 191 183;
    --color-zinc-500: 163 160 153;
    --color-zinc-700: 101 99 92;
    --color-zinc-800: 101 99 92;
    --color-zinc-900: 36 34 29;
    --color-zinc-950: 21 20 18;
    --color-chart-1-light: 42 157 144;
    --color-chart-1-dark: 38 98 217;
    --color-chart-2-light: 231 110 80;
    --color-chart-2-dark: 226 54 112;
    --color-chart-3-light: 39 71 84;
    --color-chart-3-dark: 232 140 48;
    --color-chart-4-light: 232 196 104;
    --color-chart-4-dark: 175 87 219;
    --color-chart-5-light: 244 164 98;
    --color-chart-5-dark: 46 184 138;
    --color-orange-50: 252 232 229;
    --color-orange-500: 255 81 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family:
    'Hanken Grotesk',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(input):not(textarea):not(select):not(button):not(a) {
  user-select: text;
  /* Allow text selection */
  outline: none;
  /* Prevent focus outlines */
}

div,
span {
  outline: none;
  /* Prevent focus outline on generic elements */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
