.progressBarContainer {
  width: 60px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  margin: 0 8px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background-color: #007aff;
  transition: width 0.1s linear;
  border-radius: 2px;
}
