.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 55, 66, 0.8); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: var(--color-8);
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal p {
  font-size: 12px;
  color: #6f6f6f;
  margin-top: 8px;
}

.authorId {
  font-size: 14px;
  color: var(--color-11);
  margin-bottom: 16px;
  background-color: #f9fafb;
  padding: 8px 12px;
  border-radius: 8px;
}

.modal h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
  color: var(--color-11);
  margin-bottom: 16px;
}

.modal form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--color-11);
}

.modal input,
.modal select,
.modal textarea {
  padding: 8px;
  border: 1px solid var(--color-12);
  border-radius: 8px;
  font-size: 14px;
  margin-top: 4px;
  resize: vertical;
}

.modal input:focus,
.modal select:focus,
.modal textarea:focus {
  outline: none;
  border-color: var(--color-13);
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
}

.saveButton {
  background-color: var(--color-13);
  color: var(--color-8);
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #1e6b78;
}

.cancelButton {
  background-color: #f1f4f5;
  color: var(--color-11);
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: var(--color-12);
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.pagination button {
  padding: 6px 12px;
  border: 1px solid var(--color-12);
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
