/* src/components/authorPage/Learnings.module.css */

.learnings {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card {
  display: flex;
  width: 260px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 14px;
  border-radius: 12px;
  border: 1px solid #e3e5dc;
  background:
    linear-gradient(0deg, #edf2f1 0%, #edf2f1 100%),
    linear-gradient(180deg, var(--color-10) 0%, #e5e6e0 100%);
  box-shadow:
    0px 4px 8px 0px rgba(30, 55, 66, 0.03),
    0px 2px 4px 0px rgba(30, 55, 66, 0.03);
}

.quote {
  flex: 1 0 0;
  color: var(--color-11);
  font-family: 'Crimson Pro', serif;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
}

.divider {
  align-self: stretch;
  /* No additional styles needed if the SVG is sized correctly */
}

.cardFooter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.likes {
  display: flex;
  align-items: center;
  gap: 5px;
}

.likesNumber {
  color: #6c7c83;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}

.heartIcon,
.addToFavoritesIcon {
  width: 16px;
  height: 16px;
}

/* Optional: Adjust SVG fill colors if necessary */
.heartIcon path,
.addToFavoritesIcon path {
  fill: #6c7c83;
}

@media (max-width: 768px) {
  .divider {
    display: none;
  }

  .card {
    width: 100%;
  }
}
