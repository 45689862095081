/* client/components/AboutMeModal/AboutMeModal.module.css */
.aboutMeModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding: 20px;
}

.aboutMeModal h2 {
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Crimson Pro', serif;
  font-size: 24px;
  color: var(--color-11);
}

.factList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
  min-height: 0;
}

.factItem {
  background-color: var(--color-10);
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 12px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  font-family: 'Crimson Pro', serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-11);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transform: translateY(-10px);
  animation: slideIn 0.3s ease-out forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.factItem:last-child {
  margin-bottom: 0;
}

.factItem span {
  flex-grow: 1;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}

.deleteButton {
  background: none;
  border: 1px solid #a3adb0;
  color: #a3adb0;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.deleteButton:hover {
  background-color: #a3adb0;
  color: var(--color-8);
}

.inputContainer {
  background-color: var(--color-8);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 8px 12px; /* Slightly less padding than chat input */
  margin-top: 20px;
}

.inputField {
  padding: 10px 14px; /* Slightly less padding than chat input */
  flex-grow: 1;
  border: none;
  font-family: 'Crimson Pro', serif;
  font-size: 18px; /* Slightly smaller font size */
  color: var(--color-11);
  outline: none;
  resize: none;
  overflow-y: auto;
  max-height: calc(18px * 4 + 20px); /* Adjust max height for smaller font and padding */
  line-height: 18px;
  margin-left: 10px; /* Adjust margin */
}

.saveButton {
  background-color: var(--color-13); /* Revert to a filled button */
  color: var(--color-8);
  border: none;
  padding: 10px 15px; /* Adjust padding */
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 10px; /* Add some left margin */
}

.saveButton:hover {
  background-color: #16656f;
}

.saveButton:disabled {
  background-color: var(--color-12);
  color: var(--color-8);
  cursor: not-allowed;
}

.noFactsMessage {
  text-align: center;
  font-family: 'Crimson Pro', serif;
  font-size: 16px;
  color: #a3adb0;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .aboutMeModal {
    padding: 8px;
  }

  .factItem {
    padding: 10px;
  }

  .inputField {
    padding: 10px;
  }

  .saveButton {
    padding: 10px;
  }
}
