/* src/components/authorPage/MainContent.module.css */

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding: 20px;
  background: var(--color-9);
  /* Remove overflow: hidden; */
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkboxLabel {
  margin-left: 8px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-13);
}

.scrollableContent {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.inputContainer {
  position: sticky;
  bottom: 0;
  background-color: var(--color-8);
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  align-items: center;

  /* Keep a max-width so it doesn’t expand uncontrollably on big screens */
  max-width: 1200px;

  /* Let it center horizontally and shrink on smaller screens */
  width: 100%;
  margin: 0 auto;
  padding: 0 16px; /* Some padding for smaller screens */
  box-sizing: border-box;
}

.inputField {
  margin-left: 20px;
  padding-top: 6px;
  flex-grow: 1;
  border: none;
  font-family: 'Crimson Pro', serif;
  font-size: 20px;
  color: var(--color-11);
  outline: none;
  resize: none; /* Prevent manual resizing */
  overflow-y: auto; /* Show scrollbar when content exceeds max height */
  max-height: calc(20px * 4 + 24px); /* Max height for 4 lines */
  line-height: 20px; /* Set line-height to calculate height correctly */
}

.toggleContainer {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.toggleSwitch {
  appearance: none;
  width: 40px;
  height: 20px;
  background: var(--color-12);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.toggleSwitch:checked {
  background: var(--color-13);
}

.toggleSwitch:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: var(--color-8);
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggleSwitch:checked:before {
  transform: translateX(20px);
}

.toggleLabel {
  margin-left: 8px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-13);
}

.sendButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s;
}

.sendButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.sendArrowIcon {
  width: 40px;
  height: 40px;
  color: var(--color-13);
}

/* Accessibility enhancements */
.sendButton:focus,
.toggleSwitch:focus {
  outline: 2px solid var(--color-13);
}

@media (max-width: 768px) {
  .mainContent {
    padding: 0;
    background: #f9faf7;
  }

  .inputContainer {
    bottom: 8px;
  }

  .toggleContainer {
    display: none;
  }
}
