.adminDashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-10); /* Neutral background */
  font-family: 'Hanken Grotesk', sans-serif;
}

.dashboardLayout {
  display: flex;
  flex: 1;
}

.dashboardContent {
  flex: 1;
  background-color: var(--color-8); /* Main content background */
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .dashboardLayout {
    flex-direction: column;
  }

  .dashboardContent {
    padding: 16px;
  }
}
