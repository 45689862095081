/* src/components/authorPage/Tools.module.css */

.toolsContainer {
  display: flex;
  justify-content: center;
  /*align-items: first;*/
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 28px;
  padding-bottom: 35px;
  min-height: 201px;
}

@media (max-width: 768px) {
  .toolsContainer {
    flex-direction: column;
    height: auto; /* Let height auto-expand for stacked layout */
  }
  .toolButton {
    width: 100%;
    justify-content: center;
  }
}

.toolItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toolButton {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px 25px;
  border: none;
  background: var(--color-8);
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  white-space: nowrap;
}

.toolButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.icon {
  width: 24px;
  height: 24px;
  fill: var(--color-13);
  transition: fill 0.3s;
}

.toolText {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #6c7c83;
}

.toolButton:hover .icon {
  fill: var(--color-11);
}

.toolButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(33, 128, 141, 0.3);
}

.toolButton:active {
  transform: translateY(1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
