:root {
  --color-1: #55acee; /* Light Blue */
  --color-2: #eaaa00; /* Dark Orange */
  --color-6: #a0a0a0; /* Gray */
  --color-7: #d3d3d3; /* Light Gray */
  --color-8: #ffffff; /* White */
  --color-9: #fcfcf9; /* Very Light Gray */
  --color-10: #f2f3ee; /* Light Gray-Green */
  --color-11: #1e3742; /* Gray/500 */
  --color-12: #d3d8d9; /* Soft Gray */
  --color-13: #21808d; /* Teal Blue */
}
