/* src/components/authorPage/ProfileSectionFull.module.css */

.profileSection {
  display: flex;
  min-width: 300px;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px 20px;
  gap: 16px;
  width: 322px;
  background: var(--color-9);
  box-shadow: -1px 1px 2px rgba(30, 55, 66, 0.02);
  border-radius: 16px;
  scrollbar-width: none; /* Firefox-specific property */
  -ms-overflow-style: none; /* IE and Edge */
}

.profileSection::-webkit-scrollbar {
  display: none;
}

.editBtn {
  user-select: none;
  color: var(--color-13);
  cursor: pointer;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 16px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  font-family: 'Hanken Grotesk', sans-serif;
  width: max-content;
}

.authorInfo {
  display: flex;
  flex-direction: column;
}

.authotar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileImageContainer {
  width: 115px;
  height: 115px;
  border: 4px solid var(--color-9);
  border-radius: 50%;
  overflow: hidden;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.name {
  font-family: 'Crimson Pro', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: -0.9px;
  color: var(--color-11);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.title {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  padding-top: 5px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  padding-top: 24px;
}

.infoRow {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  color: var(--color-11);
  opacity: 0.8;
}

.nameSub {
  margin-top: 44px;
  color: var(--color-11);
  font-family: 'Crimson Pro', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
}

.stats {
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.readers {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--color-11);
}

.subscribeButton {
  align-items: center;
  user-select: none;
  padding: 3px 11px 3px 11px;
  justify-content: center;
  border: 1px solid var(--color-12);
  border-radius: 50px;
  font-size: 14px;
  color: var(--color-13);
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: normal;
}

.bio {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  letter-spacing: -0.28px;
  color: var(--color-11);
  opacity: 0.7;
  text-align: start;
  margin-top: 15px;
}

.sectionDivider {
  width: 100%;
  height: 2px;
  border: none;
  background-color: #f1f4f5;
}

.link {
  color: var(--color-11);
  text-decoration: none;
}

.sectionTitle {
  margin-bottom: 16px;
  font-family: 'Crimson Pro', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
}

.lectureList,
.onTour {
  margin-top: 16px;
  width: 100%;
  background: var(--color-9);
  border-radius: 12px;
}

.lectureItem,
.tourItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
}

.iconContainer {
  width: 51px;
  height: 51px;
  background: #f1f1ee;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lectureDetails,
.tourDetails {
  display: flex;
  flex-direction: column;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  color: var(--color-11);
}

.lectureName {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--color-11);
}

.subtext {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  color: var(--color-11);
}

@media (max-width: 768px) {
  .profileSection {
    width: 100%;
    height: 100vh;
    padding: 10px 16px 20px;
    border-radius: 0;
  }

  .authotar {
    margin: -10px -16px -20px;
  }

  .profileImageContainer {
    position: relative;
    width: 100%;
    height: 251px; /* or auto if you prefer a ratio-based image */
    border: none;
    border-radius: 0;
    overflow: hidden;
    z-index: 20;
    box-sizing: border-box;
  }

  .profileImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
    object-fit: cover;
    object-position: top;
    border: none;
    border-radius: 0;
    z-index: 21;
  }

  .imageOverlay {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -2px;
    width: 100%;
    /*height: 100%;*/
    background: linear-gradient(
      180deg,
      rgba(251, 251, 248, 0) 9.9%,
      rgba(251, 251, 248, 0.565) 54.88%,
      rgba(251, 251, 248, 0.81) 69.29%,
      rgba(251, 251, 248, 0.94) 79.52%,
      #fbfbf8 88.65%
    );
    pointer-events: none; /* So it doesn't block clicks on the image below, if needed. */
  }

  .editBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 102;
  }
}
