/* src/components/authorPage/BookSection.module.css */

.bookSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  position: relative; /* For positioning Clear Chat */
  margin-right: 32px;
  margin-left: 32px;
}

.topBookSection {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center image horizontally */
}

.clearChat {
  position: absolute; /* Absolute position within .bookSection */
  top: 20px; /* Distance from the top */
  right: 20px; /* Distance from the right */
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: var(--color-8);
  padding: 10px 20px;
  background: var(--color-11); /* Button background color */
  border: none;
  border-radius: 20px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer; /* Indicate it's clickable */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.clearChat:hover {
  background: #162d35; /* Darken the background on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Increase shadow intensity */
  transform: translateY(-2px); /* Slight lift effect */
}

.clearChat:active {
  background: #0e1f24; /* Even darker background when active */
  transform: translateY(0); /* Reset lift effect */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Softer shadow on press */
}

.mobileBookInfo {
  display: none; /* hidden by default on large screens */
  flex-direction: column; /* we can tweak in media queries */
  align-items: flex-start; /* or center, your choice */
  gap: 8px; /* space between items */
}

.bookDetails {
  display: none;
}

.bookImageMobile {
  display: none; /* hidden on desktop */
}

.bookImage {
  width: 140px;
  height: auto;
  border-radius: 4px;
  background: lightgray center/cover no-repeat;
  box-shadow:
    0 17px 32px -0.627px rgba(26, 35, 39, 0.05),
    0 13px 17px -5.018px rgba(26, 35, 39, 0.11),
    0 3px 8px 0 rgba(26, 35, 39, 0.06),
    0 1px 3px 0 rgba(26, 35, 39, 0.06),
    0 0 0 1px rgba(26, 35, 39, 0.08);
  margin-bottom: 24px;
}

.tabContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 666px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 15px 11px 12px;
  gap: 8px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: var(--color-11); /* Default color for inactive tabs */
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  width: 20px;
  height: 20px;
  opacity: 0.5; /* Default opacity for inactive icons */
}

.tabText {
  opacity: 0.5; /* Default opacity for inactive text */
}

.activeTab .icon,
.activeTab .tabText {
  opacity: 1; /* Full opacity for active icons and text */
}

.activeTab {
  font-weight: 600;
  background: none;
}

.activeIndicator {
  width: 100%; /* Fill the width of the active tab */
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 40px 40px 0 0;
}

.divider {
  width: 66.66%; /* Two-thirds of the container width */
  max-width: 666px;
  height: 2px;
}

.dividerDesktop {
  display: block; /* visible on desktop by default */
}

.dividerMobile {
  display: none; /* hidden on desktop by default */
}

.contentContainer {
  width: 100%;
  margin-left: 80px;
  margin-right: 80px;
  position: relative;
}

.transitionWrapper {
  width: 100%;
  /* Ensures the transitioning component fills the container */
}

.fadeEnter {
  opacity: 0;
  transform: translateY(20px);
}

.fadeEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 200ms ease-in-out,
    transform 200ms ease-in-out;
}

.fadeExit {
  opacity: 1;
  transform: translateY(0);
}

.fadeExitActive {
  opacity: 0;
  transform: translateY(-20px);
  transition:
    opacity 200ms ease-in-out,
    transform 200ms ease-in-out;
}

@media (max-width: 768px) {
  .tabContainer {
    margin-top: 8px;
    justify-content: space-evenly;
  }
  .bookDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .bookSection {
    margin-right: 0;
    margin-left: 0;
  }
  .bookImage {
    display: none;
  }
  .topBookSection {
    margin-bottom: 16px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  /* Show the new mobile info */
  .mobileBookInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* The new small mobile cover */
  .bookImageMobile {
    display: block; /* becomes visible on mobile */
    height: 64px;
    object-fit: cover;
    border-radius: 4px; /* optional */
    margin-right: 8px;
    background: lightgray; /* fallback color if missing */
  }

  .bookTitleMobile {
    display: block; /* visible on mobile */
    font-family: 'Crimson Pro', serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: var(--color-11);
  }

  .bookSubtitleMobile {
    display: block; /* visible on mobile */
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-11);
    opacity: 0.7;
  }

  /* Optionally, reposition Clear Chat so it’s in normal flow.
       If you'd rather keep it absolute, omit this. */
  .clearChat {
    position: static;
    margin-left: auto; /* push to the right if .topBookSection is flex */
  }

  .tab {
    padding: 11px 15px 11px 15px;
    gap: 8px;
    border-radius: 55px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    box-shadow: none;
    color: var(--color-11);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  /* 2) Active tab: use the tab’s dynamic color as background
         + white text/icons + shadow from Figma */
  .activeTab {
    background: none;
    box-shadow:
      0px 5px 6px -6px rgba(83, 41, 157, 0.5),
      0px 5px 24px -6px rgba(83, 41, 157, 0.2);
  }

  .activeTab .icon,
  .activeTab .tabText {
    color: #f9faf7 !important; /* override inline style of #21808D / #C87156 / #6F499D */
    opacity: 1;
  }

  .activeIndicator {
    display: none;
  }

  .tabDetailsDivider {
    display: none;
  }

  .dividerDesktop {
    display: none; /* Hide the desktop divider on mobile */
  }
  .dividerMobile {
    display: block; /* Show the mobile divider on mobile */
  }
}
