/* AuthorList.module.css */

/* Container for all authors */
.authorsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px; /* Space between cards */
  padding: 24px; /* Padding around the container */
  background-color: var(--color-9); /* Gray/100 */
}

/* Individual author card */
.authorCard {
  position: relative; /* Added to position the status icon */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 200px;
  height: 280px;
  background: #f4f4f0;
  border-radius: 16px;
  box-shadow:
    0px 4px 12px rgba(30, 55, 66, 0.06),
    0px 2px 4px rgba(30, 55, 66, 0.06);
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s;
}

.authorCard:hover {
  background-color: #e5e7e0;
}

/* Author image */
.authorImage {
  width: 115px;
  height: 115px;
  border-radius: 50%; /* Circular images */
  object-fit: cover;
  border: 4px solid var(--color-9); /* Border matching the card background */
}

.statusIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
}

.draft {
  color: #c87156; /* Orange for draft */
}

.published {
  color: var(--color-13); /* Green for published */
}

.filterContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.filterLabel {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  color: var(--color-11);
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterCheckbox {
  width: 16px;
  height: 16px;
}

/* Author name */
.authorGreetings {
  font-family: 'Crimson Pro', serif;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: var(--color-11); /* Gray/500 */
  text-align: center;
  margin-top: 16px; /* Margin above the name */
  margin-bottom: 16px; /* Margin below the name */
  /* Handle overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 44px; /* Limit to two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

/* Author subtitle */
.authorSubtitle {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-11); /* Gray/500 */
  opacity: 0.8;
  text-align: center;
  margin-bottom: 8px; /* Margin below the subtitle */
  /* Handle overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 36px; /* Limit to two lines */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

/* Author location */
.authorLocation {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-11); /* Gray/500 */
  opacity: 0.5;
  text-align: center;
  margin-top: auto; /* Push location to the bottom if space allows */
  /* Handle overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
